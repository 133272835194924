
import { defineComponent, onMounted, ref, reactive, createVNode } from "vue";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue"
import { getLinks, updateLinks, addLinks, delLinks } from "@/api"
import { message, Modal } from 'ant-design-vue';
import { getCurrentTime } from "@/common/currentTime"

export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined
  },
  props: {
    sign: Number
  },
  setup() {
    const columns = reactive([
      { title: 'ID', width: 100, dataIndex: 'id', key: 'id' },
      { title: 'Logo', width: 100, key: 'logo', slots: { customRender: 'Logo' } },
      { title: '标题', width: 200, dataIndex: 'title', key: 'title' },
      { title: '链接', width: 300, dataIndex: 'link', key: 'link' },
      { title: '简介', width: 300, dataIndex: 'info', key: 'info' },
      { title: '创建时间', dataIndex: 'time', key: 'time' },
      {
        title: '操作',
        key: 'operation',
        width: 160,
        slots: { customRender: 'action' },
      },
    ]);
    const loading = ref(false);
    const links = reactive({data: null});
    const linkInfo = ref("");
    const modalSign = ref("添加");
    const modal = reactive({
      visible: false,
      confirmLoading: false
    })
    const id = ref(0);
    let index: number;
    const linkParams = reactive({
      id: 0,
      title: "",
      logo: "",
      info: "",
      link: "",
      time: ""
    })

    // 删除
    const isDelCom = (uid: number, idx: number) => {
      id.value = uid;
      index = idx;
      Modal.confirm({
        title: '是否删除该友情链接',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该操作不可恢复，请谨慎执行！',
        cancelText: "取消",
        okText: "确定",
        onOk() {
          loading.value = true;
          delLinks({id: id.value}).then((res: any) => {
            loading.value = false;
            if (res.code == 200) {
              modal.visible = false;
              (links as any).data.splice(index, 1);
              return message.success("删除成功");
            }
            message.error("删除失败！");
          })
        }
      });
    }
    const openEdit = (text: any, idx: number) => {
      modalSign.value = "修改";
      modal.visible = true;
      id.value = text.id;
      index = idx;
      linkInfo.value = text;
      linkParams.id = text.id;
      linkParams.info = text.info;
      linkParams.logo = text.logo;
      linkParams.link = text.link;
      linkParams.title = text.title;
    }
    const add = () => {
      linkParams.info = "";
      linkParams.logo = "";
      linkParams.link = "";
      linkParams.title = "";
      modal.visible = true;
      modalSign.value = "添加";
    }
    const Ok = () => {
      if (modalSign.value == "修改") {
        modal.confirmLoading = true;
        updateLinks(linkParams).then((res: any) => {
          modal.confirmLoading = false;
          if (res.code == 200) {
            modal.visible = false;
            (links as any).data[index].info = linkParams.info;
            (links as any).data[index].logo = linkParams.logo;
            (links as any).data[index].link = linkParams.link;
            (links as any).data[index].title = linkParams.title;
            return message.success("修改成功！");
          }
          message.error("修改失败！");
        })
        .catch(() => {
          modal.visible = false;
        })
        return;
      }
      if (linkParams.title == "") {
        return message.warning("请输入标题");
      }
      if (linkParams.logo == "") {
        return message.warning("请输入Logo链接");
      }
      if (linkParams.info == "") {
        return message.warning("请输入简介");
      }
      if (linkParams.link == "") {
        return message.warning("请输入链接地址");
      }
      modal.confirmLoading = true;
      loading.value = true;
      addLinks(linkParams).then((res: any) => {
        loading.value = false;
        modal.confirmLoading = false;
        if (res.code == 200) {
          modal.visible = false;
          linkParams.id = res.data.id;
          const time = getCurrentTime();
          linkParams.time = time;
          (links as any).data.push({
            id: linkParams.id,
            logo: linkParams.logo,
            title: linkParams.title,
            link: linkParams.link,
            info: linkParams.info,
            time: linkParams.time
          });
          return message.success("添加成功！");
        }
        message.error("添加失败！");
      })
      .catch(() => {
        modal.visible = false;
      })
    }
    onMounted(() => {
      loading.value = true;
      getLinks().then((res: any) => {
        loading.value = false;
        links.data = res.data.data.map((item: any) => {
          item.link = item.link;
          return item;
        });
      })
    })
    return {
      links,
      loading,
      columns,
      linkInfo,
      linkParams,
      modal,
      modalSign,
      isDelCom,
      Ok,
      openEdit,
      add
    }
  }
})
